<template>
  <div id="app">
    <navbar></navbar>
    <header_a></header_a>
    <index></index>
    <foo></foo>
  </div>
</template>

<script>
import foo from '@/components/Template/footer'
import navbar from "@/components/Template/navbar"
import index from "@/components/index"
import header_a from "@/components/Template/header"

export default {
  name: 'App',
  components: {
    foo, navbar, index, header_a
  }
}
</script>