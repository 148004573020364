import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import VueAxios from "vue-axios"
import {VueReCaptcha} from 'vue-recaptcha-v3'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueAxios, axios)
Vue.use(VueReCaptcha, { siteKey: '6LcBcMYcAAAAALP7-zDLBUQy6-_Sc_wLJPbcINGk' })

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
      return await this.$recaptcha('login');
    }
  },
}).$mount('#app')
